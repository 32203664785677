
import { mapState } from "vuex";

export default {
  name: "ReseauxSociaux",
  components: {
    Link : () => import("@/components/elements/Link.vue")
  },
  computed: {
    isObfuscated() {
      return (this.$route.path !== '/')
    },
     ...mapState({
      socialMedias: (state) => {
        return state.radio.radioSocialMedia;
      },
    }),
  },
  methods: {
    encode(url) {
      return Buffer.from(url).toString('base64')
    },

  }
}
